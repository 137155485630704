
class SnilsValidator {
  constructor (snils) {
    this.snils = snils
  }

  filterSNILS (s) {
    return s
      .split('')
      .filter((c) => c >= '0' && c <= '9')
      .slice(0, 11)
      .join('')
  }

  // Рассчитать контрольное число СНИЛС:
  // a) каждая цифра умножаем на номер своей позиции (позиции отсчитываем с конца, то есть, справа);
  // б) полученные произведения суммируем;
  // в) получаем остаток от деления суммы на 101;
  // г) если получилось 100, контрольное число равно 0 (факт. получаем остаток от деления остатка на 100).
  calculateSNILSChecksum (snils) {
    return (snils
      .split('')
      .map((c) => c.charCodeAt(0) - 48)
      .slice(0, 9)
      .reduce((sum, v, i) => {
        return sum + (9 - i) * v
      }, 0) % 101) % 100
  }

  // Проверить контрольное число СНИЛС, введённого в поле input, и выставить флаг валидности.
  validate () {
    if (!this.snils || !this.snils.length) {
      return 'Заполните СНИЛС'
    }

    const snils = this.filterSNILS(this.snils)
    if (snils.length < 11) {
      // СНИЛС слишком короткий, проверять нечего.
      return 'СНИЛС слишком короткий, нужно 11 цифр'
    } else {
      // Проверим контрольное число.
      const checksum = ('' + this.calculateSNILSChecksum(snils)).padStart(2, '0')
      if (snils.slice(9, 11) !== checksum) {
      // Введённое контрольное число не совпало с расчётным.
        return 'Проверьте СНИЛС, в нём ошибка: не сходится контрольное число'
      } else {
      // Контрольное число совпало, СНИЛС валиден.
        return true
      }
    }
  }
}

export const validateSNILS = snils => {
  return new SnilsValidator(snils || '').validate()
}
